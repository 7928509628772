<template>
  <section class="invoice-add-wrapper">
    <b-row
      v-if="Object.keys(orderData).length > 0"
      class="invoice-add"
    >
      <b-col
        cols="12"
      >
        <b-form
          ref="formNewOrder"
          @submit.prevent
        >
          <validation-observer
            ref="formOrder"
            #default="{}"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row class="invoice-spacing">
                  <b-col
                    cols="6"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <h6 class="mb-1">
                        {{ $t('Cliente') }}
                      </h6>
                      <v-select
                        ref="client"
                        v-model="orderData.client"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="listAllClientsToOrder"
                        append-to-body
                        :calculate-position="withPopper"
                        :loading="orderData.extra.clientIsLoading"
                        :filterable="false"
                        label="name"
                        item-text="name"
                        item-value="id_user_store"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        @search="onSearchClientsToOrder"
                        @input="onSelectedClientToOrder($event)"
                      >
                        <div
                          v-if="(listAllClientsToOrder.length === 0) && (orderData.extra.clientIsLoaded === true)"
                          slot="no-options"
                        >
                          {{ $t('Sem resultados') }}
                        </div>
                        <template
                          v-if="(listAllClientsToOrder.length === 0) && (orderData.extra.clientIsLoaded === false)"
                          slot="no-options"
                        >
                          {{ $t('Digite o nome, abreviatura, nif ou número de cliente') }}
                        </template>
                        <template
                          slot="option"
                          slot-scope="option"
                        >
                          <div class="d-flex align-items-center">
                            <div class="detail">
                              <b-card-text class="mb-0">
                                {{ option.name }}
                              </b-card-text>
                              <b-card-text
                                v-if="option.no_estab_phc"
                                class="mb-0 text-muted"
                              >
                                <small>{{ $t('Número cliente') }}: {{ option.no_estab_phc }}</small>
                              </b-card-text>
                              <b-card-text
                                v-if="option.nif_envio"
                                class="mb-0 text-muted"
                              >
                                <small>{{ $t('Número contribuinte') }}: {{ option.nif_envio }}</small>
                              </b-card-text>
                              <b-card-text
                                v-if="option.abbr_nome_phc"
                                class="mb-0 text-muted"
                              >
                                <small>{{ $t('Abreviatura') }}: {{ option.abbr_nome_phc }}</small>
                              </b-card-text>
                            </div>
                          </div>
                        </template>
                        <template
                          slot="selected-option"
                          slot-scope="option"
                        >
                          {{ option.name }}
                        </template>
                      </v-select>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row class="invoice-spacing">
                  <b-col
                    cols="6"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <h6 class="mb-1">
                        {{ $t('Morada de entrega / faturação') }}
                      </h6>
                      <v-select
                        ref="clientLocation"
                        v-model="orderData.clientLocation"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="listAllLocationsByClientToOrder"
                        append-to-body
                        :calculate-position="withPopper"
                        :loading="orderData.extra.clientLocationIsLoading"
                        :filterable="false"
                        label="profile_name"
                        item-text="profile_name"
                        item-value="profile_id"
                        :class="errors.length > 0 ? 'is-invalid':null"
                        @input="onSelectedClientLocationToOrder($event)"
                      >
                        <div
                          v-if="(listAllLocationsByClientToOrder.length === 0) && (orderData.extra.clientLocationIsLoaded === true)"
                          slot="no-options"
                        >
                          {{ $t('Sem resultados') }}
                        </div>
                        <template
                          v-if="(listAllLocationsByClientToOrder.length === 0) && (orderData.extra.clientLocationIsLoaded === false)"
                          slot="no-options"
                        >
                          {{ $t('Escolha um cliente') }}
                        </template>
                        <template
                          slot="option"
                          slot-scope="option"
                        >
                          <div class="d-flex align-items-center">
                            <div class="detail">
                              <b-card-text class="mb-0">
                                {{ option.profile_name }}
                              </b-card-text>
                            </div>
                          </div>
                        </template>
                        <template
                          slot="selected-option"
                          slot-scope="option"
                        >
                          {{ option.profile_name }}
                        </template>
                      </v-select>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row
                  v-if="profileClientSelected!== ''"
                  class="invoice-spacing"
                >
                  <b-col
                    cols="6"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-25">
                      {{ $t('Morada de entrega') }}
                    </h6>
                    <b-card-text
                      v-if="renderProfileClientSelected('s_firstname')!== ''"
                      class="mb-25"
                    >
                      {{ renderProfileClientSelected('s_firstname') }}
                    </b-card-text>
                    <b-card-text
                      v-if="renderProfileClientSelected('s_nif_envio')!==''"
                      class="mb-25"
                    >
                      {{ renderProfileClientSelected('s_nif_envio') }}
                    </b-card-text>
                    <b-card-text
                      v-if="renderProfileClientSelected('s_phone')!== ''"
                      class="mb-25"
                    >
                      {{ renderProfileClientSelected('s_phone') }}
                    </b-card-text>
                    <b-card-text
                      v-if="renderProfileClientSelected('s_address') !== ''"
                      class="mb-25"
                    >
                      {{ renderProfileClientSelected('s_address') }}
                    </b-card-text>
                    <b-card-text
                      v-if="(renderProfileClientSelected('s_zipcode') !== '') || (renderProfileClientSelected('s_city') !== '')"
                      class="mb-25"
                    >
                      {{
                        `${renderProfileClientSelected('s_zipcode')}${((renderProfileClientSelected('s_zipcode') !== '') ? ' ' : '')}${renderProfileClientSelected('s_city')}`
                      }}
                    </b-card-text>
                    <b-card-text
                      v-if="renderProfileClientSelected('s_s_concelho') !== ''"
                      class="mb-25"
                    >
                      {{ renderProfileClientSelected('s_s_concelho') }}
                    </b-card-text>
                    <b-card-text
                      v-if="renderProfileClientSelected('s_country_descr') !== ''"
                      class="mb-0"
                    >
                      {{ renderProfileClientSelected('s_country_descr') }}
                    </b-card-text>
                  </b-col>
                  <b-col
                    cols="6"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-25">
                      {{ $t('Morada de Faturação') }}
                    </h6>
                    <b-card-text
                      v-if="renderProfileClientSelected('b_firstname')!== ''"
                      class="mb-25"
                    >
                      {{ renderProfileClientSelected('b_firstname') }}
                    </b-card-text>
                    <b-card-text
                      v-if="renderProfileClientSelected('b_nif_envio')!==''"
                      class="mb-25"
                    >
                      {{ renderProfileClientSelected('b_nif_envio') }}
                    </b-card-text>
                    <b-card-text
                      v-if="renderProfileClientSelected('b_phone')!== ''"
                      class="mb-25"
                    >
                      {{ renderProfileClientSelected('b_phone') }}
                    </b-card-text>
                    <b-card-text
                      v-if="renderProfileClientSelected('b_address') !== ''"
                      class="mb-25"
                    >
                      {{ renderProfileClientSelected('b_address') }}
                    </b-card-text>
                    <b-card-text
                      v-if="(renderProfileClientSelected('b_zipcode') !== '') || (renderProfileClientSelected('b_city') !== '')"
                      class="mb-25"
                    >
                      {{
                        `${renderProfileClientSelected('b_zipcode')}${((renderProfileClientSelected('b_zipcode') !== '') ? ' ' : '')}${renderProfileClientSelected('b_city')}`
                      }}
                    </b-card-text>
                    <b-card-text
                      v-if="renderProfileClientSelected('b_s_concelho') !== ''"
                      class="mb-25"
                    >
                      {{ renderProfileClientSelected('b_s_concelho') }}
                    </b-card-text>
                    <b-card-text
                      v-if="renderProfileClientSelected('b_country_descr') !== ''"
                      class="mb-0"
                    >
                      {{ renderProfileClientSelected('b_country_descr') }}
                    </b-card-text>
                  </b-col>
                </b-row>
              </b-card-body>

              <b-card-body class="invoice-padding form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <b-row
                    v-for="(item, index) in orderData.items"
                    :key="index"
                    ref="row"
                    class="pb-2"
                  >
                    <b-col cols="12">
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <b-col
                            cols="12"
                            lg="1"
                            class="mb-1"
                          >
                            {{ $t('Referência') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="4"
                            class="mb-1"
                          >
                            {{ $t('Designação') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="1"
                            class="mb-1"
                          >
                            {{ $t('Quant.') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                            class="mb-1"
                          >
                            {{ $t('Preço') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="1"
                            class="mb-1"
                          >
                            {{ $t('Desc.1') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="1"
                            class="mb-1"
                          >
                            {{ $t('Desc.2') }}
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                            class="mb-1"
                          >
                            {{ $t('Total') }}
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <b-col
                            cols="12"
                            lg="1"
                          >
                            <label class="d-inline d-lg-none mb-1">{{ $t('Referência') }}</label>
                            <p class="mb-1">
                              {{ item.code }}
                            </p>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="4"
                          >
                            <label class="d-inline d-lg-none mb-1">{{ $t('Designação') }}</label>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <v-select
                                :ref="`item_product`"
                                v-model="item.product"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="item.extra.products"
                                append-to-body
                                :calculate-position="withPopper"
                                :filterable="false"
                                label="name"
                                item-text="name"
                                item-value="id"
                                :class="errors.length > 0 ? 'is-invalid':null"
                                @search="(search, loading) => onSearchProducts(search, loading, index)"
                                @input="val => onSelectedProduct(index, val)"
                              >
                                <div
                                  v-if="(item.extra.products.length === 0) && (item.extra.productsIsLoaded === true)"
                                  slot="no-options"
                                >
                                  {{ $t('Sem resultados') }}
                                </div>
                                <template
                                  v-if="(item.extra.products.length === 0) && (item.extra.productsIsLoaded === false)"
                                  slot="no-options"
                                >
                                  {{ $t('Pesquise por nome ou código') }}
                                </template>
                                <template
                                  slot="option"
                                  slot-scope="option"
                                >
                                  <div class="d-flex align-items-center">
                                    <b-avatar
                                      v-if="imagePreviewProductSearch(option) !== ''"
                                      :src="imagePreviewProductSearch(option)"
                                      class="mr-50"
                                    />
                                    <div class="detail">
                                      <b-card-text class="mb-0">
                                        {{ option.name }}
                                      </b-card-text>
                                      <b-card-text
                                        v-if="option.code !== ''"
                                        class="mb-0 text-muted"
                                      >
                                        <small>{{ $t('Código') }}: {{ option.code }}</small>
                                      </b-card-text>
                                    </div>
                                  </div>
                                </template>
                                <template
                                  slot="selected-option"
                                  slot-scope="option"
                                >
                                  {{ option.name }}
                                </template>
                              </v-select>
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="1"
                          >
                            <label class="d-inline d-lg-none mb-1">{{ $t('Quant.') }}</label>
                            <validation-provider
                              #default="{ errors }"
                              :rules="`required|greaterThanZero|maxValueValidate:${getAmountAvailableProduct(item)}`"
                              mode="eager"
                            >
                              <b-form-input
                                :ref="`item_amount`"
                                v-model="item.amount"
                                type="number"
                                :class="errors.length > 0 ? 'is-invalid':null"
                                @keypress="onlyNumber;"
                                @change="updateProductItem(index);"
                                @keydown.enter="updateProductItem(index, 'amount');"
                              />
                            </validation-provider>
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none mb-1">{{ $t('Preço') }}</label>
                            <b-form-input
                              type="text"
                              :value="renderValueToEur(item.price)"
                              :disabled="true"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="1"
                          >
                            <label class="d-inline d-lg-none mb-1">{{ $t('Desc.1') }}</label>
                            <VueNumeric
                              :ref="`item_discount1`"
                              v-model="item.discount1"
                              class="form-control"
                              :separator="','"
                              :decimal-separator="','"
                              :min="0"
                              :max="100"
                              :precision="2"
                              @change="updateProductItem(index)"
                              @keypress.native="NumbersDecimal"
                              @keydown.native.enter="updateProductItem(index, 'discount1')"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="1"
                          >
                            <label class="d-inline d-lg-none mb-1">{{ $t('Desc.2') }}</label>
                            <VueNumeric
                              :ref="`item_discount2`"
                              v-model="item.discount2"
                              class="form-control"
                              :separator="','"
                              :decimal-separator="','"
                              :min="0"
                              :max="100"
                              :precision="2"
                              @change="updateProductItem(index, 'discount2', $event)"
                              @keypress.native="NumbersDecimal"
                            />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            <label class="d-inline d-lg-none mb-1">{{ $t('Total') }}</label>
                            <b-form-input
                              :value="renderProductItemTotal(index)"
                              type="text"
                              :disabled="true"
                            />
                          </b-col>
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon
                            size="16"
                            icon="XIcon"
                            class="cursor-pointer"
                            @click="removeItem(index)"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="addNewItemInItemForm"
                >
                  {{ $t('Adicionar outro') }}
                </b-button>
              </b-card-body>

              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                    order="2"
                    order-md="1"
                  />
                  <b-col
                    cols="12"
                    md="6"
                    class="mt-md-6 d-flex justify-content-end"
                    order="1"
                    order-md="2"
                  >
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t('Subtotal') }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ renderValueToEur(orderData.resume.totalItems) }}
                        </p>
                      </div>

                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t('Desconto P.P') }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ renderValueToEur(orderData.resume.totalDiscountPP) }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t('Desconto comercial') }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ renderValueToEur(orderData.resume.totalDiscountComercial) }}
                        </p>
                      </div>

                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t('IVA') }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ renderValueToEur(orderData.resume.totalTax) }}
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t('Total a pagar') }}
                        </p>
                        <p class="invoice-total-amount">
                          {{ renderValueToEur(orderData.resume.totalSales) }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <hr class="invoice-spacing">

                <b-card-body class="invoice-padding pt-0">
                  <span class="font-weight-bold">{{ $t('Observações') }}: </span>
                  <b-form-textarea v-model="orderData.note" />
                </b-card-body>

              </b-card-body>

              <b-card-footer>
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="primary"
                  @click.stop.prevent="submitOrder($event, false)"
                >
                  {{ $t('Submeter encomenda') }}
                </b-button>

                <template v-if="$can('manager', 'sales')">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    class="ml-2"
                    @click.stop.prevent="submitOrder($event, true)"
                  >
                    {{ $t('Submeter encomenda (nao cria no phc)') }}
                  </b-button>
                </template>

                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-secondary"
                  class="ml-2"
                  @click.stop.prevent="resetFormOrder"
                >
                  {{ $t('Limpar') }}
                </b-button>
              </b-card-footer>

            </b-card>
          </validation-observer>
        </b-form>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardFooter,
  BButton,
  BCardText,
  BForm,
  BFormInput,
  BFormTextarea,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showMsgRequest, eventsCustomSelects, debounce } from '@core-custom/mixins/geral'
import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { greaterThanZero, maxValueValidate } from '@validations-custom'
// import { createIntl } from '@formatjs/intl'
import VueNumeric from 'vue-numeric'
import '@/libs/sweet-alerts'
import formatMoney from 'accounting-js/lib/formatMoney'
import formatNumber from 'accounting-js/lib/formatNumber'
import unformat from 'accounting-js/lib/unformat'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardFooter,
    BButton,
    BCardText,
    BForm,
    BFormInput,
    BFormTextarea,
    BAvatar,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    VueNumeric,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  computed: {
    profileClientSelected() {
      return this.orderData.clientLocation?.profile_id || ''
    },
  },
  mounted() {
    this.resetFormOrder()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...showMsgRequest.methods,
    ...eventsCustomSelects.methods,

    async addNewItemInItemForm() {
      let nItemsWithoutProduct = 0
      this.orderData.items.forEach(item => {
        if (item.product === null) {
          nItemsWithoutProduct += 1
        }
      })

      if (nItemsWithoutProduct > 0) {
        this.showMsgErrorRequest(new Error(this.$t('Tem items sem produtos definidos')))
      } else {
        this.$refs.form.style.overflow = 'hidden'
        this.orderData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        await this.searchProducts(undefined, '', (this.orderData.items.length - 1), this)

        await this.updateTotalAmount()

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      }
    },
    removeItem(index) {
      this.orderData.items.splice(index, 1)
      this.updateTotalAmount()
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    onSearchClientsToOrder(search, loading) {
      if (search.length) {
        loading(true)
        this.searchClientsToOrder(loading, search, this)
      }
    },
    searchClientsToOrder: debounce(async (loading, search, vm) => {
      const oThis = vm

      const formData = new FormData()
      formData.append('search', search || '')
      formData.append('limit', 50)

      await oThis.$store.dispatch('request/cancelTokensPendingByUrl', 'index.php?dispatch=bestquimica_api.getClientsPHC', { root: true })

      await new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}index.php?dispatch=bestquimica_api.getClientsPHC`, formData)
          .then(response => {
            if (typeof response.data === 'object') {
              resolve(response.data)
            } else {
              reject(new Error(oThis.$t('Problema ao carregar os clientes')))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(oThis.$t('Problema ao carregar os clientes')))
            }
          })
      }).then(res => {
        oThis.orderData.extra.clientIsLoaded = true
        oThis.listAllClientsToOrder = res
        loading(false)
      })
        .catch(error => {
          oThis.showMsgErrorRequest(error)
          oThis.listAllClientsToOrder = []
          loading(false)
        })
    }, 500),

    async onSelectedClientToOrder(oValue) {
      await this.onSearchLocationsByClientToOrder(oValue)
      await this.searchProducts(undefined, '', 0, this)
      this.focusNext('clientLocation')
    },
    async onSearchLocationsByClientToOrder(oValue) {
      if (oValue?.id_user_store !== undefined) {
        const oThis = this

        oThis.orderData.clientLocation = null
        oThis.orderData.extra.clientLocationIsLoading = true

        const formData = new FormData()
        formData.append('idUser', oValue.id_user_store)

        await oThis.$store.dispatch('request/cancelTokensPendingByUrl', 'index.php?dispatch=bestquimica_api.getProfilesByClients', { root: true })

        await new Promise((resolve, reject) => {
          api.post(`${apiConfig.url_base_api}index.php?dispatch=bestquimica_api.getProfilesByClients`, formData)
            .then(response => {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else {
                reject(new Error(oThis.$t('Problema ao carregar as moradas do cliente')))
              }
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(oThis.$t('Problema ao carregar as moradas do cliente')))
              }
            })
        }).then(res => {
          oThis.orderData.extra.clientLocationIsLoaded = true
          oThis.listAllLocationsByClientToOrder = res
          oThis.orderData.extra.clientLocationIsLoading = false
        })
          .catch(error => {
            oThis.showMsgErrorRequest(error)
            oThis.listAllLocationsByClientToOrder = []
            oThis.orderData.extra.clientLocationIsLoading = false
          })
      } else {
        this.listAllLocationsByClientToOrder = []
      }
    },

    async onSelectedClientLocationToOrder() {
      this.orderData.items.forEach((item, index) => {
        this.updateProductItem(index)
      })
      this.focusNext('item_product', 0)
    },

    renderProfileClientSelected(field) {
      return this.orderData.clientLocation?.[field] || ''
    },

    onSearchProducts(search, loading, index) {
      if (search.length) {
        loading(true)
        this.searchProducts(loading, search, index, this)
      }
    },
    searchProducts: debounce(async (loading, search, index, vm) => {
      const oThis = vm

      if (oThis.orderData.client?.id_user_store !== undefined) {
        const formData = new FormData()
        formData.append('search', search || '')
        formData.append('limit', 50)
        formData.append('idUser', oThis.orderData.client.id_user_store || '')

        await oThis.$store.dispatch('request/cancelTokensPendingByUrl', 'index.php?dispatch=bestquimica_api.getProductsByClient', { root: true })

        await new Promise((resolve, reject) => {
          api.post(`${apiConfig.url_base_api}index.php?dispatch=bestquimica_api.getProductsByClient`, formData)
            .then(response => {
              if (typeof response.data === 'object') {
                resolve(response.data)
              } else {
                reject(new Error(oThis.$t('Problema ao carregar os produtos')))
              }
            })
            .catch(error => {
              if ((error.response !== undefined) && (error.response.status !== undefined)) {
                reject(new Error(oThis.$t('Problema ao carregar os produtos')))
              }
            })
        }).then(res => {
          oThis.orderData.items[index].extra.productsIsLoaded = true
          oThis.orderData.items[index].extra.products = res

          if (loading !== undefined) {
            loading(false)
          }
        })
          .catch(error => {
            oThis.showMsgErrorRequest(error)
            oThis.orderData.items[index].extra.products = []

            if (loading !== undefined) {
              loading(false)
            }
          })
      } else {
        oThis.showMsgErrorRequest(new Error(oThis.$t('Tem de escolher um cliente')))

        if (loading !== undefined) {
          loading(false)
        }
      }
    }, 500),

    imagePreviewProductSearch(option) {
      return option?.data?.image?.image_path || ''
    },

    getAmountAvailableProduct() {
      // Neste momento foi retirada a validacao do stock
      const resValue = 10000
      return Number(resValue)
      /*
      if (item?.product?.amount !== undefined) {
        resValue = item?.product?.amount || 0
      } else if (item?.amount !== undefined) {
        resValue = item?.amount || 0
      }
      */
    },

    onSelectedProduct(index, product) {
      if (product?.id !== undefined) {
        if (this.getAmountAvailableProduct(product) === 0) {
          this.orderData.items[index].product = null
          this.showMsgErrorRequest({ message: this.$t('Este produto não tem stock disponível') })
        } else {
          this.orderData.items[index].productData = product || {}

          this.orderData.items[index].code = product?.code || ''
          this.orderData.items[index].amount = 0
          this.orderData.items[index].price = product?.data?.list_price || 0
          this.orderData.items[index].discount1 = 0
          this.orderData.items[index].discount2 = 0
          this.orderData.items[index].discountTablePrice = 0

          // Calcular desconto 1 (caso tenha o preco definido na tabela de preços)
          const sPriceList = product?.data?.list_price || 0
          const sPriceSales = product?.data?.price || 0
          const discountPrice = (100 - ((sPriceSales * 100) / sPriceList))

          if (discountPrice > 0) {
            this.orderData.items[index].discount1 = discountPrice
            this.orderData.items[index].discountTablePrice = discountPrice
          }

          this.updateProductItem(index)
          this.updateTotalAmount()

          this.focusNext('item_amount', index)
        }
      }
    },

    updateProductItem(index, field, oEvent) {
      const oOrderData = this.orderData
      const oItem = oOrderData.items[index]
      let skipFocusField = false

      // Verifica se a quantidade pretendida existe em stock
      if (this.getAmountAvailableProduct(oItem.productData) > 0) {
        if (Number(oItem.amount) > this.getAmountAvailableProduct(oItem.productData)) {
          oItem.amount = 0
          this.showMsgErrorRequest({ message: `Neste momento não existe em stock a quantidade pretendida. (Stock atual: ${oItem.productData.amount})` })
          skipFocusField = true

          if (field === 'amount') {
            this.focusNext('item_amount', index)
          }
        }
      } else {
        oItem.amount = 0
      }

      // Caso o cliente tenha a condição de P.P
      if ((field !== undefined) && (field === 'discount2')) {
        let sValDiscount2User = unformat(oEvent.target.value.replaceAll(',', '.'))

        if (sValDiscount2User > 100) {
          sValDiscount2User = 100
        } else if (sValDiscount2User < 0) {
          sValDiscount2User = 0
        }

        oItem.discount2 = Number(sValDiscount2User)
        oItem.discount2Skip = true
      } else if ((oItem?.discount2Skip !== undefined) && (oItem.discount2Skip === true)) {
        //
      } else if ((oOrderData?.clientLocation?.b_desconto !== undefined) && (Number(oOrderData.clientLocation.b_desconto) > 0)) {
        oItem.discount2 = Number(oOrderData.clientLocation.b_desconto)
      }

      // Total * quantidade
      oItem.total = (Number(oItem.amount) * oItem.price)

      // Aplicar desconto 1
      oItem.totalDiscount = 0
      oItem.totalDiscountComercial = 0
      oItem.totalDiscountPP = 0

      if ((oItem?.discount1 !== undefined) && (oItem.discount1 > 0)) {
        const totalDiscount1 = ((oItem.total * oItem.discount1) / 100)
        oItem.total -= totalDiscount1
        oItem.totalDiscount += totalDiscount1
        oItem.totalDiscountComercial += totalDiscount1
      }

      if ((oItem?.discount2 !== undefined) && (oItem.discount2 > 0)) {
        const totalDiscount2 = ((oItem.total * oItem.discount2) / 100)
        oItem.total -= totalDiscount2
        oItem.totalDiscount += totalDiscount2
        oItem.totalDiscountPP += totalDiscount2
      }

      // Total IVA
      oItem.totalTaxes = 0
      if ((oItem.productData?.data?.tax_value !== undefined) && (parseFloat(oItem.productData.data.tax_value) > 0)) {
        oItem.totalTaxes = ((this.orderData.items[index].total * parseFloat(oItem.productData.data.tax_value)) / 100)
      }

      oOrderData.items[index] = oItem

      this.orderData = { ...oOrderData }

      this.updateTotalAmount()

      if (skipFocusField === false) {
        if (field === 'amount') {
          this.focusNext('item_discount1', index)
        }

        if (field === 'discount1') {
          this.focusNext('item_discount2', index)
        }
      }
    },

    renderProductItemTotal(index) {
      return this.renderValueToEur(this.orderData.items[index].total || 0)
    },

    renderValueToEur(value) {
      /*
      return this.intl.formatNumber(value, {
        style: 'currency',
        currency: 'EUR',
      }) */

      return formatMoney(value, {
        symbol: '€', format: '%v %s', precision: 2, thousand: ' ', decimal: ',',
      })
    },

    updateTotalAmount() {
      this.orderData.resume.totalItems = 0
      this.orderData.resume.totalDiscount = 0
      this.orderData.resume.totalDiscountComercial = 0
      this.orderData.resume.totalDiscountPP = 0
      this.orderData.resume.totalTax = 0
      this.orderData.resume.totalSales = 0

      this.orderData.items.forEach(item => {
        this.orderData.resume.totalItems += item.total
        this.orderData.resume.totalDiscount += item.totalDiscount
        this.orderData.resume.totalDiscountComercial += item.totalDiscountComercial
        this.orderData.resume.totalDiscountPP += item.totalDiscountPP
        this.orderData.resume.totalTax += item.totalTaxes
      })

      this.orderData.resume.totalItems = formatNumber(this.orderData.resume.totalItems, {
        precision: 2, thousand: '', decimal: '.',
      })

      this.orderData.resume.totalDiscount = formatNumber(this.orderData.resume.totalDiscount, {
        precision: 2, thousand: '', decimal: '.',
      })

      this.orderData.resume.totalTax = formatNumber(this.orderData.resume.totalTax, {
        precision: 2, thousand: '', decimal: '.',
      })

      this.orderData.resume.totalSales = (unformat(this.orderData.resume.totalItems) + unformat(this.orderData.resume.totalTax))
    },

    onlyNumber($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault()
      }
    },

    NumbersDecimal(evt) {
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 44) {
        evt.preventDefault()
        return false
      }
      return true
    },

    focusNext(field, fieldIndex) {
      this.$nextTick(() => {
        if ((this.$refs[field] !== undefined) && (fieldIndex === undefined)) {
          const input = this.$refs[field].$el.querySelector('input')
          input.focus()
        } else if ((this.$refs[field] !== undefined) && (fieldIndex !== undefined)) {
          const input = this.$refs[field][fieldIndex]?.$el?.querySelector('input')
          if ((input !== undefined) && (input !== null)) {
            input.focus()
          } else {
            this.$refs[field][fieldIndex].$el.select()
          }
        }
      })
    },

    resetFormOrder() {
      this.listAllClientsToOrder = []
      this.listAllLocationsByClientToOrder = []
      this.orderData = {
        client: null,
        clientLocation: null,
        items: [JSON.parse(JSON.stringify(this.itemFormBlankItem))],
        note: '',
        extra: {
          clientIsLoaded: false,
          clientLocationIsLoaded: false,
        },
        resume: {
          totalItems: 0,
          totalDiscount: 0,
          totalDiscountPP: 0,
          totalDiscountComercial: 0,
          totalTax: 0,
          totalSales: 0,
        },
      }

      this.$nextTick(() => {
        this.initTrHeight()
        this.$refs.formOrder.reset()
      })
    },

    async submitOrder(evt, modeTest) {
      const oThis = this
      evt.preventDefault()

      await this.$refs.formOrder.validate()
        .then(async valid => {
          if (valid === true) {
            this.$root.$emit('app::loading', true)

            const aCloneItems = { ...this.orderData?.items || [] }

            if (aCloneItems.length > 0) {
              aCloneItems.forEach((item, index) => {
                delete aCloneItems[index].extra
              })
            }

            const formNewOrder = new FormData()
            formNewOrder.append('id_user', this.orderData?.client?.id_user_store || 0)
            formNewOrder.append('id_user_phc', this.orderData?.client?.id_phc || 0)
            formNewOrder.append('id_profile', this.orderData?.clientLocation?.profile_id || 0)
            formNewOrder.append('note', this.orderData?.note || '')
            formNewOrder.append('resume', JSON.stringify(this.orderData?.resume || {}))
            formNewOrder.append('items', JSON.stringify(aCloneItems || []))
            formNewOrder.append('modeEnvTest', modeTest || false)

            if ((this.orderData?.items !== undefined) && (this.orderData.items.length > 0)) {
              this.orderData.items.forEach((item, index) => {
                if ((item?.product?.id !== undefined) && (item.product.id !== '')) {
                  formNewOrder.append(`lineProduct[${index}][id]`, item.product.id)
                  formNewOrder.append(`lineProduct[${index}][amount]`, item?.amount || 0)
                  formNewOrder.append(`lineProduct[${index}][discount1]`, item?.discount1 || 0)
                  formNewOrder.append(`lineProduct[${index}][discount2]`, item?.discount2 || 0)
                }
              })
            }

            await new Promise((resolve, reject) => {
              api.post(`${apiConfig.url_base_api}index.php?dispatch=bestquimica_api.createNewOrder`, formNewOrder)
                .then(response => {
                  if (typeof response.data === 'object') {
                    resolve(response.data)
                  } else {
                    reject(new Error(oThis.$t('Problema ao submeter a encomenda')))
                  }
                })
                .catch(error => {
                  if ((error.response !== undefined) && (error.response.status !== undefined)) {
                    reject(new Error(oThis.$t('Problema ao submter a encomenda')))
                  }
                })
            }).then(res => {
              if (res.error === 1) {
                oThis.showMsgErrorRequest({ message: res.msg })
              } else {
                oThis.showMsgSuccessRequest({ message: res.msg })
                this.resetFormOrder()
              }

              this.$root.$emit('app::loading', false)
            })
              .catch(error => {
                oThis.showMsgErrorRequest(error)
                this.$root.$emit('app::loading', false)
              })
          } else if (valid === false) {
            this.showMsgErrorRequest({ message: this.$t('Preencha os campos da encomenda') })
          }
        })
    },

  },
  setup() {
    const listAllClientsToOrder = ref([])
    const listAllLocationsByClientToOrder = ref([])
    const orderData = ref({})

    const itemFormBlankItem = {
      extra: {
        products: [],
        productsIsLoaded: false,
      },
      productData: {},
      code: null,
      product: null,
      amount: 0,
      price: 0,
      discount1: 0,
      discount2: 0,
      discountTablePrice: 0,
      total: 0,
      totalTaxes: 0,
      totalDiscount: 0,
      totalDiscountComercial: 0,
      totalDiscountPP: 0,
    }

    /*
    const intl = createIntl(
      {
        locale: 'pt-PT',
        messages: {},
      },
    )
    */

    return {
      listAllClientsToOrder,
      listAllLocationsByClientToOrder,
      orderData,
      itemFormBlankItem,
      // intl,
      greaterThanZero,
      maxValueValidate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.invoice-add {
  .invoice-total-wrapper {
    width: 100%;
    max-width: 20rem;
  }
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  background: #fff;

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }

  &.is-invalid::v-deep {
    .vs__dropdown-toggle {
      border: 1px solid #ff0000 !important;
    }
  }
}

.dark-layout {
  .v-select {
    background: none;
  }

  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
